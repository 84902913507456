import service from '@/utils/request';

// @Tags RmTool
// @Summary 创建RmTool
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body model.RmTool true "创建RmTool"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"获取成功"}"
// @Router /rmTool/createRmTool [post]
export var createRmTool = function createRmTool(data) {
  return service({
    url: "/rmTool/createRmTool",
    method: 'post',
    data: data
  });
};

// @Tags RmTool
// @Summary 删除RmTool
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body model.RmTool true "删除RmTool"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"删除成功"}"
// @Router /rmTool/deleteRmTool [delete]
export var deleteRmTool = function deleteRmTool(data) {
  return service({
    url: "/rmTool/deleteRmTool",
    method: 'delete',
    data: data
  });
};

// @Tags RmTool
// @Summary 删除RmTool
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body request.IdsReq true "批量删除RmTool"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"删除成功"}"
// @Router /rmTool/deleteRmTool [delete]
export var deleteRmToolByIds = function deleteRmToolByIds(data) {
  return service({
    url: "/rmTool/deleteRmToolByIds",
    method: 'delete',
    data: data
  });
};

// @Tags RmTool
// @Summary 更新RmTool
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body model.RmTool true "更新RmTool"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"更新成功"}"
// @Router /rmTool/updateRmTool [put]
export var updateRmTool = function updateRmTool(data) {
  return service({
    url: "/rmTool/updateRmTool",
    method: 'put',
    data: data
  });
};

// @Tags RmTool
// @Summary 用id查询RmTool
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body model.RmTool true "用id查询RmTool"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"查询成功"}"
// @Router /rmTool/findRmTool [get]
export var findRmTool = function findRmTool(params) {
  return service({
    url: "/rmTool/findRmTool",
    method: 'get',
    params: params
  });
};

// @Tags RmTool
// @Summary 分页获取RmTool列表
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body request.PageInfo true "分页获取RmTool列表"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"获取成功"}"
// @Router /rmTool/getRmToolList [get]
export var getRmToolList = function getRmToolList(params) {
  return service({
    url: "/rmTool/getRmToolList",
    method: 'get',
    params: params
  });
};
export var getAllRmToolList = function getAllRmToolList(params) {
  return service({
    url: "/rmTool/getAllRmToolList",
    method: 'get',
    params: params
  });
};